import { t } from '@lingui/macro'
import classNames from 'classnames'
import styles from './index.module.css'

type GameOnlineUsersProps = {
  livePt?: number // 在玩人数
}

export default function GameOnlineUsers(props: GameOnlineUsersProps) {
  const { livePt = 0 } = props

  return (
    <div className={classNames(styles['game-online-users'])}>
      <div className={`game-nums`}>
        <div className="circle" />
        <div className="num">{livePt}</div>
        <label>{t`features_recreation_component_game_online_users_index_kh0jn8mixt`}</label>
      </div>
    </div>
  )
}
